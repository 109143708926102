import webSymbols from "../web_symbols.json";

function checkIfPaidIcon(iconId: string): boolean {
    for (const category of webSymbols.categories) {
        for (const symbol of category.symbols) {
            if (symbol.id === iconId) {
                return symbol.isFree;
            }
        }
    }
    return true;
}

export default checkIfPaidIcon;