import { type IconProps as MuiIconProps, SvgIcon } from "@mui/material";
import MuiIcon from "@mui/material/Icon";
import {
  DEFAULT_TASK_MATERIAL_ICON,
  DEFAULT_TASK_SYMBOL_ID,
} from "@structured/utils/icons";
import { getF7Icon } from "@structured/utils/icons/custom_symbols/scripts/getF7Icon";
import classNames from "classnames";
import React, { CSSProperties } from "react";

import sprite from "../assets/sprite/sprite.svg";

export interface IcoProps extends Omit<MuiIconProps, "fontSize" | "onClick"> {
  readonly name: string;
  readonly fontSize?: string;
  readonly onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  readonly sx?: MuiIconProps["sx"];
}

export const Icon: React.FC<IcoProps> = ({
  name,
  className,
  fontSize = "1.5rem",
  onClick,
  sx,
  ...props
}) => {
  const conditionalPrefixes = [
    "custom.",
    "mdi.",
    "noun.",
    "framework7."
  ]
  return conditionalPrefixes.some((prefix) => name.startsWith(prefix)) ? (
    <SvgIcon
      sx={{ fontSize, ...sx }}
      component="svg"
      className={classNames([
        "relative transition-transform flex justify-center items-center h-[1rem] w-[1rem]",
        "fill",
        { "cursor-pointer": onClick },
      ])}
      onClick={(e: React.MouseEvent<Element, MouseEvent>) => onClick?.(e)}
      {...props}
    >
      <use href={`${sprite}#${getF7Icon(name)}`} />
    </SvgIcon>
  ) : (
    <MuiIconComponent name={name} className={className} fontSize={fontSize} onClick={onClick} sx={sx} {...props} />
  );
};

const MuiIconComponent: React.FC<IcoProps> = ({
  name,
  className,
  fontSize = "1.5rem",
  onClick,
  ...props
}) => {
  return (
    <MuiIcon
      sx={{ fontSize, ...props.sx }}
      className={classNames([
        className,
        "f7-icons fill",
        { "cursor-pointer": onClick },
      ])}
      onClick={(e) => onClick?.(e)}
      {...props}
    >
      {name === DEFAULT_TASK_SYMBOL_ID ? DEFAULT_TASK_MATERIAL_ICON : name}
    </MuiIcon>
  );
};