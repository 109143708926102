/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { type RxDatabase, type RxReplicationWriteToMasterRow } from "rxdb";
import { type RxCollection, type RxJsonSchema } from "rxdb/dist/types/types";
import { type RxReplicationState } from "rxdb/plugins/replication";

import { supabase, SUPABASE_URL } from "../api";
import { lazySetInterval } from "../common";
import { removeNonSchemaFields } from "./functions";
import {
  RECEIVED_AT_FIELD,
  type RxAllCollections,
  type RxGenericReplicationDocumentNaked,
  TableName,
} from "./rxdb.types";
import {
  RECURRING_OCCURRENCE_SCHEMA,
  RECURRING_SCHEMA,
  type RxRecurringNaked,
  type RxRecurringOccurrenceNaked,
  type RxSettingsEntryNaked,
  type RxTaskNaked,
  SETTINGS_SCHEMA,
  TASK_SCHEMA,
} from "./schemas";
import { SupabaseReplication } from "./supabase-replication";

const SCHEMA_VERSION_KEY = "schema_version";
const CHECK_REMOTE_UPDATES_INTERVAL = 15 * 1000;

// DOC: https://rxdb.info/replication.html
export async function startReplicationForAll(
  database: RxDatabase<RxAllCollections>,
  userId: string
): Promise<RxReplicationState<any, any>[]> {
  return await Promise.all([
    startReplication<RxSettingsEntryNaked | any>(
      userId,
      TableName.Settings,
      database.settings,
      SETTINGS_SCHEMA,
      "user_id"
    ),
    startReplication<RxTaskNaked>(
      userId,
      TableName.Task,
      database.task,
      TASK_SCHEMA
    ),
    startReplication<RxRecurringNaked>(
      userId,
      TableName.Recurring,
      database.recurring,
      RECURRING_SCHEMA
    ),
    startReplication<RxRecurringOccurrenceNaked>(
      userId,
      TableName.RecurringOccurrence,
      database.recurring_occurrence,
      RECURRING_OCCURRENCE_SCHEMA
    ),
  ]);
}

export function startReplication<
  RxDocType extends RxGenericReplicationDocumentNaked
>(
  userId: string,
  tableName: TableName,
  collection: RxCollection<RxDocType>,
  schema:
    | RxJsonSchema<RxSettingsEntryNaked>
    | RxJsonSchema<RxTaskNaked>
    | RxJsonSchema<RxRecurringNaked>
    | RxJsonSchema<RxRecurringOccurrenceNaked>,
  idKey = "id"
): SupabaseReplication<RxDocType> {
  const replicationState = new SupabaseReplication({
    userId,
    table: tableName,
    supabaseClient: supabase,
    collection,
    primaryKey: idKey,
    replicationIdentifier: "structuredReplication" + SUPABASE_URL,
    pull: {
      lastModifiedField: RECEIVED_AT_FIELD,
      modifier: (doc) => {
        const cleanedDoc = removeNonSchemaFields(doc, schema.properties);
        if (tableName === TableName.Settings) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
          return collection.upsert(cleanedDoc);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cleanedDoc;
      },
    },
    push: {
      updateHandler: async (
        row: RxReplicationWriteToMasterRow<RxDocType>
      ): Promise<boolean> => {
        const doc = {
          ...row.newDocumentState,
          [SCHEMA_VERSION_KEY]: schema.version,
        };

        const { error, count } = await supabase
          .from(tableName as any)
          .update(row.newDocumentState, { count: "exact" })
          .eq(idKey, doc[idKey])
          .eq(
            "replication_revision",
            row.assumedMasterState.replication_revision
          );

        if (error) throw error;
        return count === 1;
      },
    },
  });

  lazySetInterval(() => {
    replicationState.reSync();
  }, CHECK_REMOTE_UPDATES_INTERVAL);

  let resyncCalled = false;

  supabase.auth.onAuthStateChange((_event, session) => {
    if (session && !resyncCalled) {
      replicationState.reSync();
      resyncCalled = true;
    }
  });

  replicationState.error$.subscribe((err) => {
    console.error(`## replicationState.error$(${tableName}):`);
    console.dir(err);
  });

  return replicationState;
}