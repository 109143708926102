import webSymbols from "../web_symbols.json"

function isF7Icon(iconId: string): boolean {
    for (const category of webSymbols.categories) {
        for (const symbol of category.symbols) {
            if (symbol.id === iconId && symbol.code.startsWith("framework7.")) {
                return true;
            }
        }
    }
    return false;
}

export default isF7Icon;