import symbols from "./custom_symbols";
import type { IconListEntry, IconMap, IconsByCategoryEntry } from "./icons.types";
import iconCategories from "./json/iconCategories.json";
import * as icons from "./json/icons.json";
import iconsByCategory from "./json/iconsByCategory.json";

const ICON_NAME_EDGE_CASES = [
  "sun.haze.fill",
  "suit.club.fill",
  "suit.spade.fill",
  "suit.diamond.fill",
  "chart.pie.fill",
  "chart.bar.fill",
  "chevron.left.slash.chevron.right"
];

export const DEFAULT_TASK_MATERIAL_ICON = "playlist_add_check";
export const DEFAULT_TASK_SYMBOL_ID = "___NOT_YET_GIVEN___";

export const ICON_LIST: IconListEntry[] = Object.keys(icons).map(
  (id) =>
  ({
    ...icons[id],
    id,
  } as IconListEntry)
);

function mergeIconsForCategory(existingCategory: IconsByCategoryEntry, symbolsCategory: typeof symbols.categories[0]) {
  const existingIcons = new Map(existingCategory.icons.map(icon => [icon.materialId, icon]));
  const symbolIcons = symbolsCategory.symbols.map(symbol => ({
    id: symbol.id,
    materialId: symbol.code.replace("material.", ""),
    name: ICON_NAME_EDGE_CASES.includes(symbol.id) ? symbol.id : symbol.id.split(".")[0],
    categoryId: existingCategory.id
  }));

  symbolIcons.forEach(icon => { existingIcons.set(icon.materialId, icon) });

  return {
    ...existingCategory,
    icons: Array.from(existingIcons.values())
  };
}

const MERGED_ICONS_BY_CATEGORY = [...iconsByCategory].map(category => {
  const matchingSymbolCategory = symbols.categories.find(sc =>
    sc.identifier === category.id
  );

  const categoryWithMaterialId: IconsByCategoryEntry = {
    ...category,
    iconMaterialId: category.iconMaterialId || category.iconId
  };

  if (matchingSymbolCategory) {
    return mergeIconsForCategory(categoryWithMaterialId, matchingSymbolCategory);
  }
  return categoryWithMaterialId;
}).map(category => {
  const uniqueIcons = new Map(category.icons.map(icon => [icon.materialId, icon]));
  return {
    ...category,
    icons: Array.from(uniqueIcons.values())
  };
});

console.log(MERGED_ICONS_BY_CATEGORY)

export const ICONS_MAP = icons as IconMap;
export const ICONS_BY_CATEGORY = MERGED_ICONS_BY_CATEGORY;
export const ICON_CATEGORIES = iconCategories as IconsByCategoryEntry[];
