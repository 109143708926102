import { SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Icon } from "@structured/components/Icon";
import { type Task, useOccurrences, useUI } from "@structured/store";
import { getThemeColor } from "@structured/utils/colors";
import {
  DEFAULT_TASK_MATERIAL_ICON,
  getMaterialIdFromSymbolId,
} from "@structured/utils/icons";
import { getF7Icon } from "@structured/utils/icons/custom_symbols/scripts/getF7Icon";
import isF7Icon from "@structured/utils/icons/custom_symbols/scripts/isF7Icon";
import {
  type RxRecurringNaked,
  type RxRecurringOccurrenceNaked,
  type RxTaskNaked,
} from "@structured/utils/rxdb";
import classNames from "classnames";

import sprite from "../../../assets/sprite/f7_sprite.svg";
import styles from "./AllDayTask.module.scss";
export const AllDayTask = (props: {
  taskOrRecurring: Task | RxRecurringNaked;
  day: Date;
}) => {
  const { getDailyOccurrenceForRecurringTask } = useOccurrences();
  const { setContextModalTask } = useUI();
  const task = props.taskOrRecurring;

  const dailyOccurrences: RxRecurringOccurrenceNaked | null =
    getDailyOccurrenceForRecurringTask(task.id, props.day);

  const completedAt = dailyOccurrences
    ? dailyOccurrences.completed_at
    : (task as RxTaskNaked).completed_at ?? null;

  return (
    <div className="flex flex-col justify-center items-center text-center gap-1.5">
      <Box
        onClick={() =>
          setContextModalTask({
            taskOrRecurring: task,
            dayTimestamp: props.day.getTime(),
          })
        }
        className={styles.AllDayTask__Icon}
        data-completed={!!completedAt}
        sx={{
          backgroundColor: (theme) =>
            completedAt
              ? getThemeColor(task.color, theme)
              : theme.palette.divider,
        }}
      >
        <Box
          sx={{
            color: (theme) => completedAt
              ? getThemeColor(task.color, theme, "contrastText")
              : getThemeColor(task.color, theme),
          }}
        >
          {isF7Icon(task.symbol) ? (
            <SvgIcon
              sx={{
                fontSize: "1.25rem",
              }}
              component="svg"
              className="rounded-full relative transition-transform flex justify-center items-center h-[1rem] w-[1rem]"
            >
              <use href={`${sprite}#${getF7Icon(task.symbol)}`} />
            </SvgIcon>
          ) : (
            <Icon
              fontSize="1.25rem"
              className="rounded-full relative transition-transform flex justify-center items-center h-[1rem] w-[1rem]"
              name={task.symbol.startsWith("custom") ? task.symbol : getMaterialIdFromSymbolId(task.symbol)}
            />
          )}
        </Box>
      </Box>
      <Typography
        textAlign="center"
        fontWeight={500}
        fontSize={"0.75rem"}
        color={completedAt ? "text.secondary" : "text.primary"}
        sx={{
          opacity: completedAt ? 0.6 : 1,
          textDecoration: completedAt ? "line-through" : "none",
        }}
      >
        {task.title.length > 15 ? `${task.title.slice(0, 15)}...` : task.title}
      </Typography>
    </div>
  );
};
