import { Check } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { SvgIcon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@structured/components";
import type { Task } from "@structured/store";
import { getThemeColor } from "@structured/utils/colors";
import { useFirstFreePeriod, useTask } from "@structured/utils/hooks";
import { getMaterialIdFromSymbolId } from "@structured/utils/icons";
import { getF7Icon } from "@structured/utils/icons/custom_symbols/scripts/getF7Icon";
import isF7Icon from "@structured/utils/icons/custom_symbols/scripts/isF7Icon";
import { useTaskEditModal } from "@structured/views/TaskEditModal/useTaskEditModal";
import React, { useState } from "react";

import sprite from "../../../assets/sprite/f7_sprite.svg";

export interface InboxTaskProps {
  readonly task: Task;
  readonly day: Date;
}

export const InboxTask: React.FC<InboxTaskProps> = (props) => {
  const { updateCompletedAt } = useTaskEditModal({
    taskDay: props.day,
    taskOrRecurring: props.task,
    onSubmit: () => { const defaultDuration = 15 },
  });
  const taskContext = useTask(props.task, props.day);
  const [isHovered, setIsHovered] = useState(false);
  const completeTask = (taskContext) => {
    updateCompletedAt();
  };

  const firstPossibleStartTime = useFirstFreePeriod(
    props.day,
    new Date(),
    taskContext.task.duration ?? 15
  );

  return (
    <>
      <Box
        my={"1.25rem"}
        py={"0.75rem"}
        px={"0.75rem"}
        borderRadius={"0.625rem"}
        onClick={(e) => {
          taskContext.openEditModal();
          e.stopPropagation();
        }}
        onMouseEnter={() => !taskContext.completedAt && setIsHovered(true)}
        onMouseLeave={() => !taskContext.completedAt && setIsHovered(false)}
        bgcolor={(theme) => theme.palette.mode === "dark" ? "#1C1C1E" : "#F8F8F8"}
        sx={{
          "&:hover": { background: (theme) => getThemeColor(taskContext.task.color, theme, "light") },
          alignItems: taskContext.subtasks?.length ? "flex-start" : "center",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} alignContent={"center"}>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            {isHovered && !taskContext.completedAt ? (
              <Icon
                name="check_circle"
                sx={{
                  pr: 1,
                  color: (theme) => getThemeColor(taskContext.task.color, theme)
                }}
                onClick={(e) => {
                  completeTask(taskContext);
                  e.stopPropagation();
                }}
              />
            ) : isF7Icon(taskContext.task.symbol) ? (
              <SvgIcon
                sx={{

                  color: (theme) => getThemeColor(taskContext.task.color, theme)
                }}
                component="svg"
              >
                <use href={`${sprite}#${getF7Icon(taskContext.task.symbol)}`} />
              </SvgIcon>
            ) : (
              <Icon
                fontSize="1.7125rem"
                name={taskContext.task.symbol.startsWith("custom") ? taskContext.task.symbol : getMaterialIdFromSymbolId(taskContext.task.symbol)}
                sx={{
                  color: (theme) =>
                    getThemeColor(taskContext.task.color, theme)
                }}
              />
            )}
            <Box>
              {!taskContext.completedAt && (
                <Typography
                  fontWeight={400}
                  fontSize={"0.75rem"}
                  lineHeight={"1.25rem"}
                  color={"text.secondary"}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {taskContext.formattedTime}
                </Typography>
              )}
              <Typography
                fontWeight={600}
                fontSize={"0.875rem"}
                lineHeight={"1.375rem"}
                variant="subtitle2"
                color={taskContext.completedAt ? "text.secondary" : "text.primary"}
                sx={{ textDecoration: taskContext.completedAt ? "line-through" : "none" }}
              >
                {taskContext.task.title}
              </Typography>
            </Box>
          </Box>
          <Box pt={taskContext.completedAt ? 0 : 0.5}>
            <IconButton
              onClick={(e) => {
                if (taskContext.completedAt) {
                  completeTask(taskContext);
                } else {
                  taskContext.openEditModal({
                    is_in_inbox: false,
                    start_time: (firstPossibleStartTime.getHours() + firstPossibleStartTime.getMinutes() / 60)
                  });
                }
                e.stopPropagation();
              }}
              sx={{
                color: (theme) =>
                  getThemeColor(taskContext.task.color, theme)
              }}
            >
              {taskContext.completedAt ? (
                isHovered ? <Check sx={{ height: "1.25rem", width: "1.25rem" }} /> : <Check sx={{ height: "1.25rem", width: "1.25rem" }} />
              ) : (
                <Add sx={{ height: "1.25rem", width: "1.25rem" }} />
              )}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
