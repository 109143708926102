import { Badge, SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import { Icon } from "@structured/components/Icon";
import type { IconListEntry } from "@structured/utils/icons";
import checkIfPaidIcon from "@structured/utils/icons/custom_symbols/scripts/checkIfPaidIcon";
import { getF7Icon } from "@structured/utils/icons/custom_symbols/scripts/getF7Icon";
import isF7Icon from "@structured/utils/icons/custom_symbols/scripts/isF7Icon";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import sprite from "../../assets/sprite/f7_sprite.svg"
import { type RootState } from "../../redux/store";
import styles from "./IconPicker.module.scss";

export interface IconPickerEntryProps {
  readonly icon: IconListEntry;
  readonly isSelected: boolean;
  readonly onSelect: () => void;
}

export const IconPickerEntry: React.FC<IconPickerEntryProps> = (props) => {
  const isPro = useSelector((state: RootState) => state.isPro.isPro);
  const isFree = checkIfPaidIcon(props.icon.id);
  const isF7 = isF7Icon(props.icon.id);

  const handleOpenSettings = () => {
    const settingsBtn = document.getElementById("settings-button") as SettingsButtonElement | null;
    if (settingsBtn) {
      settingsBtn.openWithTab = "Structured Pro";
      settingsBtn.click();
    }
  };

  if (isF7) {
    return (
      <Badge badgeContent={"★"} color="primary" overlap="circular" sx={{ "& .MuiBadge-badge": { fontSize: 12, padding: "0 3px" } }} invisible={isPro || isFree}>
        <Box
          onClick={() => {
            if (isFree || isPro) {
              props.onSelect();
            } else {
              handleOpenSettings();
            }
          }}
          id={props.icon.id}
          className={classNames(styles.IconPicker__Entry, "IconPicker__Entry")}
          sx={{
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
            backgroundColor: (theme) =>
              props.isSelected ? theme.palette.primary.main : theme.palette.divider,
            cursor: "pointer",
            opacity: isFree || isPro ? 1 : 0.5,
            color: (theme) => props.isSelected ? theme.palette.primary.contrastText : theme.palette.text.primary,
          }}
        >
          <SvgIcon
            sx={{
              fontSize: "1.5rem",
              ".IconPicker__Entry:hover &": {
                color: (theme) => theme.palette.primary.contrastText,
              },
            }}
            component="svg"
            className={classNames([
              "relative transition-transform flex justify-center items-center h-[1rem] w-[1rem]",
              "fill",
            ])}
            {...props}
          >
            <use href={`${sprite}#${getF7Icon(props.icon.name)}`} />
          </SvgIcon>
        </Box>
      </Badge>
    );
  }

  return (
    <>
      {!isPro && !isFree && (
        <Badge
          badgeContent={"★"}
          color="primary"
          overlap="circular"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: 12,
              padding: "0 3px"
            }
          }}
        >
          <Box
            onClick={() => {
              if (isFree || isPro) {
                props.onSelect();
              } else {
                handleOpenSettings();
              }
            }}
            id={props.icon.id}
            className={classNames(styles.IconPicker__Entry, "IconPicker__Entry")}
            sx={{
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
              },
              backgroundColor: (theme) =>
                props.isSelected ? theme.palette.primary.main : theme.palette.divider,
              cursor: "pointer",
              opacity: isFree || isPro ? 1 : 0.5,
            }}
          >
            <Icon
              sx={{
                ".IconPicker__Entry:hover &": {
                  color: (theme) => theme.palette.primary.contrastText,
                },
                color: (theme) =>
                  props.isSelected
                    ? theme.palette.primary.contrastText
                    : theme.palette.text.primary,
              }}
              name={props.icon.materialId}
            />
          </Box>
        </Badge>
      )}
      {(isPro || isFree) && (
        <Box
          onClick={() => {
            if (isFree || isPro) {
              props.onSelect();
            } else {
              handleOpenSettings();
            }
          }}
          id={props.icon.id}
          className={classNames(styles.IconPicker__Entry, "IconPicker__Entry")}
          sx={{
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
            backgroundColor: (theme) =>
              props.isSelected ? theme.palette.primary.main : theme.palette.divider,
            cursor: "pointer",
            opacity: isFree || isPro ? 1 : 0.5,
          }}
        >
          <Icon
            sx={{
              ".IconPicker__Entry:hover &": {
                color: (theme) => theme.palette.primary.contrastText,
              },
              color: (theme) =>
                props.isSelected
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.primary,
            }}
            name={props.icon.materialId}
          />
        </Box>
      )}
    </>
  );
};


interface SettingsButtonElement extends HTMLButtonElement {
  openWithTab?: string;
}
