const edgeCasesF7 = [
    ["sun.haze.fill", "sun_haze_fill"],
    ["suit.diamond.fill", "suit_diamond_fill"],
    ["suit.club.fill", "suit_club_fill"],
    ["suit.spade.fill", "suit_spade_fill"],
    ["suit.heart.fill", "suit_heart_fill"],
    ["chart.pie.fill", "chart_pie_fill"],
    ["chart.bar.fill", "chart_bar_fill"],
    ["chevron.left.slash.chevron.right", "chevron_left_slash_chevron_right"],
]

export default edgeCasesF7;