import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, SvgIcon } from "@mui/material";
import { Icon } from "@structured/components/Icon";
import { useUI } from "@structured/store/ui/ui.provider";
import { getThemeColor } from "@structured/utils/colors";
import dimPrimaryBackground from "@structured/utils/colors/functions/dimPrimaryBackground";
import { getMaterialIdFromSymbolId } from "@structured/utils/icons";
import { getF7Icon } from "@structured/utils/icons/custom_symbols/scripts/getF7Icon";
import isF7Icon from "@structured/utils/icons/custom_symbols/scripts/isF7Icon";
import { type TimelineEntry, type TimelineTaskEntry } from "@structured/utils/timeline";
import React, { useMemo } from "react";

import sprite from "../../../../assets/sprite/f7_sprite.svg";
import { TimelineTimes } from "../TimelineTimes/TimelineTimes";

export interface TimelineNormalLineProps {
  readonly entry: TimelineTaskEntry;
  readonly expanded?: boolean;
  readonly isOverlapping?: boolean;
  readonly previousEntry?: TimelineEntry;
  readonly nextEntry?: TimelineEntry;
  readonly previousWasDashed?: boolean;
}

export const TimelineNormalLine: React.FC<TimelineNormalLineProps> = ({
  entry,
  expanded,
  isOverlapping,
  previousEntry,
  nextEntry,
  previousWasDashed,
}) => {
  const { setContextModalTask } = useUI();
  const taskOrRecurring = entry.taskOrRecurring;
  const taskDay: Date = entry.start;

  const taskHeight = useMemo(() => {
    const duration = entry.taskOrRecurring.duration ?? 0;
    let BASE = 64;

    if (duration <= 1) {
      BASE = 48;
    }

    const minDuration = 15;
    const maxDuration = 120;
    const maxMultiple = 4;
    const a: number = (BASE * (maxMultiple - 1)) / (maxDuration - minDuration);
    const b: number =
      BASE -
      ((BASE * (maxMultiple - 1)) / (maxDuration - minDuration)) * minDuration;
    const result: number = a * duration + b;

    return Math.min(Math.max(result, BASE), BASE * maxMultiple);
  }, [entry.taskOrRecurring.duration]);

  return (
    <TimelineSeparator>
      <TimelineConnector
        sx={{
          background: (theme) =>
            entry.progress > 0
              ? previousWasDashed ? getThemeColor(taskOrRecurring.color, theme) :
                previousEntry && !isOverlapping ? `linear-gradient(to top, ${getThemeColor(taskOrRecurring.color, theme)}, ${getThemeColor(taskOrRecurring.color, theme)}, ${getThemeColor(taskOrRecurring.color, theme)},   ${getThemeColor(previousEntry.color, theme)})` : theme.palette.divider
              : theme.palette.divider,
          maxHeight: expanded ? "3.3rem" : "100%",
          transition: (theme) =>
            !expanded
              ? theme.transitions.create("max-height", {
                delay: theme.transitions.duration.shortest,
              })
              : "none",
        }}
      />
      <TimelineDot
        sx={{
          marginLeft: "0.575rem",
          cursor: "pointer",
          height: taskHeight,
          width: "3rem",
          background: (theme) =>
            `linear-gradient(${getThemeColor(taskOrRecurring.color, theme)} ${entry.progress
            }%, ${theme.palette.divider} ${entry.progress && entry.progress + 5
            }%)`,
          transition: (theme) =>
            theme.transitions.create("background", {
              delay: theme.transitions.duration.shortest,
            }),
        }}
        onClick={() =>
          setContextModalTask({
            taskOrRecurring,
            dayTimestamp: taskDay.getTime(),
          })
        }

      >
        <Box className="flex justify-center items-center w-full"
          sx={{
            color: (theme) =>
              entry.progress >= 40
                ? getThemeColor(entry.color, theme, "contrastText")
                : getThemeColor(entry.color, theme),
          }}
        >
          {isF7Icon(taskOrRecurring.symbol) ? (
            <SvgIcon
              sx={{
                fontSize: "1.25rem",
              }}
              component="svg"
              className="rounded-full relative transition-transform flex justify-center items-center h-[1rem] w-[1rem]"
            >
              <use href={`${sprite}#${getF7Icon(taskOrRecurring.symbol)}`} />
            </SvgIcon>
          ) : (
            <Icon
              fontSize="1.25rem"
              className="rounded-full relative transition-transform flex justify-center items-center h-[1rem] w-[1rem]"
              name={taskOrRecurring.symbol.startsWith("custom") ? taskOrRecurring.symbol : getMaterialIdFromSymbolId(taskOrRecurring.symbol)}
            />
          )}
        </Box>
      </TimelineDot>
      <TimelineConnector
        sx={{
          background: (theme) =>
            entry.progress === 100
              ? !isOverlapping ? getThemeColor(taskOrRecurring.color, theme) : theme.palette.divider
              : theme.palette.divider,
        }}
      />
    </TimelineSeparator>
  );
};
