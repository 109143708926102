import { ChangeCircle as ChangeCircleIcon, ExpandMore } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { type UseDateFieldProps } from "@mui/x-date-pickers/DateField";
import {
  DatePicker,
  type DatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import {
  type BaseSingleInputFieldProps,
  type DateValidationError,
  type FieldSection,
} from "@mui/x-date-pickers/models";
import { useSettings } from "@structured/store";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale"
import weekday from "dayjs/plugin/weekday";
import React from "react";

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
  BaseSingleInputFieldProps<
    Dayjs | null,
    Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}

const ButtonField = (props: ButtonFieldProps) => {
  const {
    open,
    setOpen,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  const { settings } = useSettings();

  dayjs.extend(weekday)
  dayjs.extend(localeData);
  React.useEffect(() => {
    if (settings.first_weekday === 0) {
      dayjs.updateLocale("en", {
        weekStart: 0,
      });
    } else if (settings.first_weekday !== undefined || settings.first_weekday !== null) {
      dayjs.updateLocale("en", {
        weekStart: settings.first_weekday - 1,
      });
    }

  }, [settings.first_weekday]);

  dayjs.extend(updateLocale);

  return (
    <button
      disabled={disabled}
      id={id}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      className="ml-2"
    >
      <ExpandMore className={`${open ? "rotate-180 transition ease-in-out delay-150" : ""}`} />
    </button>
  );
};

interface ButtonDatePickerProps extends Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose"> {
  selectedDay: Date;
  onDaySelect: (day: Date) => void;
}

export const ButtonDatePicker = (props: ButtonDatePickerProps) => {
  const [open, setOpen] = React.useState(false);
  const locales = "en-US";
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const popperStyles = {
    position: "relative",
    "& .MuiPaper-root": {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      marginTop: "0.5rem",
      zIndex: 1000,
      borderRadius: "1rem",
      boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.12)",
      minWidth: "20rem",
      "& .MuiPickersLayout-root": {
        padding: "0.5rem",
        minHeight: "auto"
      }
    },
    "& .MuiDateCalendar-root": {
      maxHeight: "18.5rem"
    },
  };

  return (
    <div className="relative inline-flex items-center justify-center" ref={wrapperRef} >
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <Typography
          variant="h3"
          fontWeight={700}
          component="span"
          fontSize={"1.25rem"}
        >
          {props.selectedDay.toLocaleDateString(locales, { month: "long" })}
          &nbsp;
          <Typography display="inline" variant="inherit" color="primary">
            {props.selectedDay.toLocaleDateString(locales, {
              year: "numeric",
            })}
          </Typography>
        </Typography>
      </div>
      <DatePicker
        slots={{
          field: ButtonField,
          ...props.slots
        }}

        slotProps={{
          desktopPaper: {
            sx: {
              ".MuiPickersYear-yearButton.Mui-selected":{
                backgroundColor: "primary.main",
              },
              ".MuiPickersYear-yearButton.Mui-selected:focus":{
                backgroundColor: "primary.main",
              },
            }
          },
          day: {
            sx: {
              "&.MuiPickersDay-root.Mui-selected": {
                backgroundColor: "primary.main",
              }
            },
          },

          field: { open, setOpen } as any,
          popper: {
            sx: popperStyles,
            anchorEl: wrapperRef.current,
          },
        }}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    </div>
  );
};