import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, IconButton, InputAdornment, Popover, SvgIcon, TextField } from "@mui/material";
import { ColorSelector, Icon } from "@structured/components";
import { getMaterialIdFromSymbolId } from "@structured/utils/icons";
import getAllDeprecatedIcons from "@structured/utils/icons/custom_symbols/scripts/getAllDeprecatedIcons";
import { getF7Icon } from "@structured/utils/icons/custom_symbols/scripts/getF7Icon";
import isF7Icon from "@structured/utils/icons/custom_symbols/scripts/isF7Icon";
import { type TmpTask } from "@structured/utils/tasks";
import { IconPickerPopover } from "@structured/views/IconPicker/IconPickerPopover";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { type Control, Controller, type UseFormRegister, type UseFormSetValue } from "react-hook-form";

import sprite from "../../../assets/sprite/f7_sprite.svg"
import { type FormValues } from "../TaskForm";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface TitleInputRowProps {
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
    disabled?: boolean;
    isShowIconPicker: boolean;
    setIsShowIconPicker: (show: boolean) => void;
    setValue: UseFormSetValue<FormValues>;
    isEdit?: boolean;
    task: TmpTask;
    readonly onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
}

const TitleInputRow: React.FC<TitleInputRowProps> = ({ control, register, disabled, task, isShowIconPicker, setIsShowIconPicker, setValue, onUpdateTmpTask }) => {
    const [iconAnchorEl, setIconAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [colorAnchorEl, setColorAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [renderF7Icon, setRenderF7Icon] = useState(false);
    const openIconPopover = Boolean(iconAnchorEl);
    const openColorPopover = Boolean(colorAnchorEl);
    const iconId = openIconPopover ? "icon-popover" : undefined;
    const colorId = openColorPopover ? "color-popover" : undefined;
    const handleColorChange = (color: string) => {
        setDisplayColor(color);
        onUpdateTmpTask({ ...task, color });
    };
    const handleTitleChange = (title: string) => {
        onUpdateTmpTask({ ...task, title });
    };
    const handleSymbolChange = (symbol: string) => {
        onUpdateTmpTask({ ...task, symbol });
    }
    const getIconSymbol = (symbol: string) => {

        if (symbol.startsWith("custom")) {
            return symbol;
        }
        if (isF7Icon(symbol)) {
            setRenderF7Icon(true);
            return symbol;
        }
        const isMat = getMaterialIdFromSymbolId(symbol);
        if (isMat === "playlist_add_check") {
            const deprecatedIcons = getAllDeprecatedIcons(symbol);
            for (const deprecatedIcon of deprecatedIcons) {
                if (getMaterialIdFromSymbolId(deprecatedIcon) !== "playlist_add_check") {
                    return deprecatedIcon;
                }
            }
            return "playlist_add_check";
        }
        return symbol;
    }
    const [displayColor, setDisplayColor] = useState(task.color);
    useEffect(() => {
        if (task.symbol) {
            if (isF7Icon(task.symbol)) {
                setRenderF7Icon(true);
            } else {
                setRenderF7Icon(false);
            }
        }
    }, [task.symbol]);
    return (
        <TaskFormRowContainer>
            <Popover
                id={iconId}
                open={openIconPopover}
                anchorEl={iconAnchorEl}
                onClose={() => setIconAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                style={{
                    borderRadius: "1rem",
                }}
            >
                {isShowIconPicker && (
                    <Controller
                        control={control}
                        name="task.symbol"
                        disabled={disabled}
                        render={({ field }) => (
                            <IconPickerPopover
                                selectedIconId={field.value}
                                onClose={() => setIsShowIconPicker(false)}
                                onSelectIcon={(symbol) => {
                                    setIconAnchorEl(null);
                                    const parsedSymbol = getIconSymbol(symbol);
                                    field.onChange(parsedSymbol ?? undefined);
                                    setIsShowIconPicker(false);
                                    handleSymbolChange(parsedSymbol);
                                }}
                            />
                        )}
                    />
                )}
            </Popover>
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.main + "33",
                    borderRadius: "0.5rem",
                    width: "3rem",
                }}
                disableRipple
                disabled={disabled}
                onClick={(e) => {
                    setIsShowIconPicker(true);
                    setIconAnchorEl(e.currentTarget);
                }}
                aria-describedby={iconId}
            >
                {renderF7Icon ? (
                    <>
                        <SvgIcon
                            color="primary"
                            sx={{
                                fontSize: "1.5rem",
                                ".IconPicker__Entry:hover &": {
                                    color: (theme) => theme.palette.primary.contrastText,
                                },
                            }}
                            component="svg"
                            className={classNames([
                                "relative transition-transform flex justify-center items-center h-[1rem] w-[1rem]",
                                "fill",
                            ])}
                        >
                            <use href={`${sprite}#${getF7Icon(task.symbol)}`} />
                        </SvgIcon>
                    </>
                ) : (
                    <>
                        <Icon
                            sx={{ color: (theme) => theme.palette.primary.main }}
                            name={task.symbol.startsWith("custom") ? task.symbol : getMaterialIdFromSymbolId(task.symbol)}
                        />
                    </>
                )}
            </IconButton>

            <TextField
                {...register("task.title", { required: true, disabled })}
                fullWidth
                type="text"
                autoFocus
                placeholder="Task Title"
                onChange={(e) => {
                    handleTitleChange(e.target.value);
                    setValue("task.title", e.target.value);
                }}
                InputProps={{
                    style: {
                        fontSize: "1.125rem",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "1.375rem",
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                onClick={(e) => setColorAnchorEl(e.currentTarget)}
                                aria-label="Add"
                                disableRipple
                                disableElevation
                                aria-describedby={colorId}
                                sx={{
                                    background: (theme) => theme.palette.mode === "dark" ? "#1C1C1E" : "#F8F8F8",
                                    position: "absolute",
                                    right: 0,
                                    height: "100%",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                }}
                            >
                                <Box component={"span"} sx={{ padding: "0.5rem" }} >
                                    <ColorSelector
                                        value={displayColor}
                                        onSelect={handleColorChange}
                                        onClose={() => setColorAnchorEl(null)}
                                        variant="button"
                                        disableMenu
                                    />
                                </Box>
                                <ExpandMoreIcon sx={{ transform: openColorPopover ? "rotate(180deg)" : "", color: (theme) => theme.palette.text.primary, marginRight: "0.5rem" }} fontSize="small" />
                            </Button>
                        </InputAdornment>
                    )
                }}
            />
            <Popover
                id={colorId}
                open={openColorPopover}
                anchorEl={colorAnchorEl}
                onClose={() => setColorAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <ColorSelector
                    value={task.color}
                    onSelect={handleColorChange}
                    variant="paper"
                />
            </Popover>

        </TaskFormRowContainer>
    );
};

export default TitleInputRow;