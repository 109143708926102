import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/Inbox";
import { Button, ButtonBase, ButtonGroup, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ButtonDatePicker } from "@structured/components/ButtonDatePicker";
import {
  useOccurrences,
  useRecurringTasks,
  useSettings,
  useTasks,
} from "@structured/store";
import { useUI } from "@structured/store/ui/ui.provider";
import { resyncBackend } from "@structured/utils/api";
import { getIsoStrDateOnly, getWeekDayName } from "@structured/utils/date";
import { getDailyRecurringTasks } from "@structured/utils/tasks";
import { SettingsCmp } from "@structured/views/Settings/SettingsCmp";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import React, { useEffect, useMemo, useState } from "react";

import { SelectDay, type SelectDayProps } from "../SelectDay/SelectDay";
import styles from "./SelectDayBar.module.scss";

dayjs.extend(weekday)

export const SelectDayBar = (props: {
  selectedDay: Date;
  onDaySelect: (day: Date) => void;
}) => {
  const [numberOfDays] = useState(7);
  const [adjustedSelectedDay, setAdjustedSelectedDay] = useState(props.selectedDay);
  const locales = "en-US";
  const { getVisibleTasks } = useTasks();
  const { recurringTasks } = useRecurringTasks();
  const { occurrences } = useOccurrences();
  const {
    settings: { first_weekday: firstDayOfWeek },
  } = useSettings();
  const {
    drawer: { isOpen, width },
    toggleIsDrawerOpen,
  } = useUI();

  useEffect(() => {
    if (firstDayOfWeek === undefined) {
      const day = new Date();
      const currentDayOfWeek = day.getDay();
      const diff = currentDayOfWeek - getStardDayOfTheWeekLocal() + 1;
      day.setDate(day.getDate() - diff);
      setAdjustedSelectedDay(day);
      return;
    }
    const day = new Date(props.selectedDay);
    const currentDayOfWeek = day.getDay();
    const diff = currentDayOfWeek - firstDayOfWeek + 1;
    day.setDate(day.getDate() - diff + (firstDayOfWeek === 0 ? getStardDayOfTheWeekLocal() : 0));
    setAdjustedSelectedDay(day);
  }, [props.selectedDay, firstDayOfWeek]);

  const getStardDayOfTheWeekLocal = () => {
    const day = dayjs().weekday(0);
    const dayName = day.format("dddd");
    if (dayName === "Sunday") return 1;
    if (dayName === "Monday") return 2;
  }

  const daysInWeek: Omit<SelectDayProps, "onDaySelect">[] = useMemo(
    () =>
      [...Array(numberOfDays)].map((_, i) => {
        const day = new Date(adjustedSelectedDay);
        day.setDate(day.getDate() + i);
        const isoDate = getIsoStrDateOnly(day);
        const tasksForDay = getVisibleTasks().filter(
          (t) => t.localDay === isoDate
        );
        const recurringForDay = getDailyRecurringTasks(
          recurringTasks,
          occurrences,
          isoDate
        );
        const allTasksForDay = [...tasksForDay, ...recurringForDay].slice(0, 5);

        return {
          date: day,
          dayNr: day.getDate(),
          title: getWeekDayName(day),
          isSelected: day.getDate() === props.selectedDay.getDate(),
          tasksForDay: allTasksForDay,
        };
      }),
    [numberOfDays, adjustedSelectedDay, getVisibleTasks, props.selectedDay, recurringTasks, occurrences]
  );

  const goPrevWeek = () => {
    const newDate = new Date(props.selectedDay);
    newDate.setDate(newDate.getDate() - 7);
    props.onDaySelect(newDate);
  };

  const goNextWeek = () => {
    const newDate = new Date(props.selectedDay);
    newDate.setDate(newDate.getDate() + 7);
    props.onDaySelect(newDate);
  };

  const theme = useTheme();

  return (
    <Box className={styles.SelectDayBar}>
      <Box className={styles.SelectDayBar__Week}>
        <div className="flex justify-center items-center gap-1" >
          {!isOpen && (
            <Box px={0} pr={1.5} component={"button"} sx={{ color: (theme) => theme.palette.text.secondary }} onClick={() => toggleIsDrawerOpen()}>
              <InboxIcon sx={{ fontSize: "1.375rem" }} />
            </Box>
          )}
          <ButtonDatePicker
            value={dayjs(props.selectedDay)}
            onChange={(ev) => props.onDaySelect(ev.toDate())}
            onDaySelect={(day) => props.onDaySelect(day)}
            selectedDay={props.selectedDay}
          />
        </div>
        <div className="flex justify-between items-center gap-1">
          <Box
            sx={{
              display: "inline-flex",
              border: "0.1rem solid",
              borderColor: theme.palette.text.secondary,
              overflow: "hidden",
              borderRadius: "0.5rem",
              height: "1.75rem"
            }}
          >
            <ButtonGroup
              variant="text"
              sx={{
                width: "100%",
                height: "100%",
                "& .MuiButtonGroup-grouped:not(:last-of-type)": {
                  borderRight: "none"
                },
                "& .MuiButtonGroup-grouped": {
                  minWidth: "2rem"
                }
              }}
            >
              <Button
                onClick={goPrevWeek}
                sx={{
                  height: "100%",
                  border: "none",
                  "&:hover": {
                    border: "none"
                  }
                }}
              >
                <ChevronLeftIcon sx={{ width: "1rem", height: "1rem", color: theme.palette.text.secondary }} />
              </Button>
              <ButtonBase
                onClick={() => props.onDaySelect(new Date())}
                TouchRippleProps={{ style: { color: theme.palette.primary.main } }}
                sx={{
                  borderRadius: 0,
                  px: "0.625rem",
                  height: "100%",
                  borderRight: "0.1rem solid",
                  borderLeft: "0.1rem solid",
                  borderColor: theme.palette.text.secondary,
                }}
              >
                <Typography fontSize={"0.75rem"} fontWeight={500} color={"text.secondary"}>
                  Today
                </Typography>
              </ButtonBase>
              <Button
                onClick={goNextWeek}
                sx={{
                  height: "100%",
                  border: "none",
                  "&:hover": {
                    border: "none"
                  }
                }}
              >
                <ChevronRightIcon sx={{ width: "1rem", height: "1rem", color: theme.palette.text.secondary }} />
              </Button>
            </ButtonGroup>
          </Box>
          <SettingsCmp triggerResync={resyncBackend} />
        </div>
      </Box>
      <Box paddingTop={"0.0625rem"} paddingBottom="0.75rem" className="flex justify-between px-4">
        {daysInWeek.map((day) => (
          <SelectDay
            {...day}
            key={day.date.getTime()}
            onDaySelect={props.onDaySelect}
          ></SelectDay>
        ))}
      </Box>
    </Box>
  );
};