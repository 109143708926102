import { type Task } from "@structured/store";
import { DEFAULT_TASK_DURATION } from "@structured/utils/common";
import { getIsoStrDateOnly, strTimeToDbFloat } from "@structured/utils/date";
import {
  useFirstFreePeriod,
  useTaskContextModal,
} from "@structured/utils/hooks";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import { type RecurringOwnFields } from "@structured/utils/tasks";
import { useState } from "react";

import { RecurringDeletePromptModal } from "../RecurringDeletePromptModal/RecurringDeletePromptModal";
import { TaskAddModal } from "../TaskAddModal/TaskAddModal";
import { TaskEditModal } from "../TaskEditModal/TaskEditModal";

export const TaskContextModal = (props: {
  taskOrRecurring: Task | RxRecurringNaked;
  dayTimestamp: number;
  onClose: () => void;
}) => {
  const taskDay: Date = new Date(props.dayTimestamp);

  const [isShowEditTaskModal, setIsShowEditTaskModal] = useState(false);
  const [isShowCopyTaskModal, setIsShowCopyTaskModal] = useState(false);
  const [isShowRecurringDeletePromptModal, setIsShowRecurringDeleteModal] =
    useState(false);
  const [isShowAddInboxTaskModal, setIsShowAddInboxTaskModal] = useState(false);
  const [recurringConfig, setRecurringConfig] = useState<RecurringOwnFields | null>(null);
  const [isCopiedTask, setIsCopiedTask] = useState(false);
  const {
    deleteAllRecurring,
    deleteSingleRecurring,
    deleteFutureRecurring,
    taskOrRecurring,
    isRecurring,
    isCalendarTask,
    deleteSingleTask,
    hideTask,
  } = useTaskContextModal(taskDay, props.taskOrRecurring, props.onClose);

  const firstPossibleStartTime = useFirstFreePeriod(
    taskDay,
    new Date(),
    taskOrRecurring.duration ?? DEFAULT_TASK_DURATION
  );

  const handleDelete = () => {
    if (isCalendarTask) {
      hideTask();
    } else if (isRecurring) {
      setIsShowRecurringDeleteModal(true);
    } else {
      deleteSingleTask();
    }
  };

  return (
    <>
      {isShowRecurringDeletePromptModal && (
        <RecurringDeletePromptModal
          onClose={() => setIsShowRecurringDeleteModal(false)}
          onDeleteAll={() => deleteAllRecurring()}
          onDeleteSingle={() => deleteSingleRecurring()}
          onDeleteFuture={() => deleteFutureRecurring()}
        />
      )}
      {(isShowEditTaskModal || taskDay) && (
        <TaskEditModal
          taskDay={taskDay}
          onCancel={() => {
            setIsShowEditTaskModal(false);
            props.onClose();
          }}
          onDelete={() => handleDelete()}
          onCopy={() => {
            setIsShowCopyTaskModal(true);
            setIsShowEditTaskModal(false);
            setIsCopiedTask(true);
          }}
          onSubmit={() => props.onClose()}
          taskOrRecurring={taskOrRecurring}
          setRecurringConfig={setRecurringConfig}
          isCalendarTask={isCalendarTask}
        />
      )}
      {isShowCopyTaskModal && (
        <TaskAddModal
          onClose={() => {
            setIsShowEditTaskModal(false);
            setIsShowCopyTaskModal(false);
            props.onClose();
          }}
          copiedTaskOrRecurring={taskOrRecurring}
          recurringConfig={recurringConfig}
          isCopiedTask={isCopiedTask}
        />
      )}

      {isShowAddInboxTaskModal && (
        <TaskEditModal
          title="Add"
          taskDay={taskDay}
          onCancel={() => {
            setIsShowAddInboxTaskModal(false);
            props.onClose();
          }}
          onDelete={() => handleDelete()}
          onSubmit={() => props.onClose()}
          taskOrRecurring={{
            ...taskOrRecurring,
            day: getIsoStrDateOnly(taskDay),
            is_in_inbox: false,
            start_time: strTimeToDbFloat(
              getIsoStrDateOnly(firstPossibleStartTime)
            ),
          }}
          isCalendarTask={isCalendarTask}
        />
      )}
    </>
  );
};
