import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { type TmpTask } from "@structured/utils/tasks";
import dayjs from "dayjs";
import { useEffect } from "react";
import { type Control, Controller, type UseFormRegister, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskRowIcon } from "./shared/TaskRowIcon";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface ScheduleAndAllDayInputRowProps {
    disabled: boolean;
    control: Control<FormValues>;
    readonly onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
    task: TmpTask;

}
export const ScheduleAndAllDayInputRow = (props: ScheduleAndAllDayInputRowProps) => {
    const onUpdateAllDayTask = (isAllDay: boolean) => {
        props.onUpdateTmpTask({ ...props.task, is_all_day: isAllDay });
    }
    const onUpdateDayChange = (day: string) => {
        props.onUpdateTmpTask({ ...props.task, day });
    }

    useEffect(() => {
        const initialDate = dayjs(props.task.day);
        const year = initialDate.year();
        const day = initialDate.date() > 9 ? initialDate.date() : `0${initialDate.date()}`;
        const month = initialDate.month() > 8 ? initialDate.month() + 1 : `0${initialDate.month() + 1}`;
        const formattedDate = `${year}-${month}-${day}`;
        onUpdateDayChange(formattedDate);
    }, []);

    return (
        <TaskFormRowContainer>
            <TaskRowIcon disabled={props.disabled}>
                <CalendarMonthOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <Box
                sx={{
                    display: "flex",
                    width: "31.3125rem",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <Controller
                    control={props.control}
                    name="task.day"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <DatePicker
                            slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
                            slotProps={{
                                textField: { size: "small" },
                                openPickerIcon: { fontSize: "inherit" },
                                openPickerButton: { size: "small" },
                            }}
                            value={dayjs(value)}
                            onChange={(ev) => {
                                const year = ev.year();
                                const day = ev.date() > 9 ? ev.date() : `0${ev.date()}`;
                                const month = ev.month() > 8 ? ev.month() + 1 : `0${ev.month() + 1}`;
                                onChange(`${year}-${month}-${day}`);
                                onUpdateDayChange(`${year}-${month}-${day}`);
                            }}
                            {...field}
                        />
                    )}
                />
                <FormControlLabel
                    label={<Typography variant="body2">All-Day</Typography>}
                    labelPlacement="end"
                    control={
                        <Controller
                            control={props.control}
                            name="task.is_all_day"
                            disabled={props.disabled}
                            render={({ field: { onChange, value, ...field } }) => (
                                <Checkbox
                                    size="small"
                                    checked={value}
                                    onChange={(ev) => {
                                        onChange(ev.target.checked);
                                        onUpdateAllDayTask(ev.target.checked);
                                    }}
                                    {...field}
                                ></Checkbox>
                            )}
                        />
                    }
                />
            </Box>
        </TaskFormRowContainer>
    );
}