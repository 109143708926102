import { type Recurring, type Task } from "@structured/store";
import dayjs from "dayjs";

import { TIMEZONES } from "../timezones";

export const addLocalTimeToTask = <T extends Task | Recurring>(task: T): T => {
  const timezones = TIMEZONES;
  if ("recurring_type" in task) {
    if (!task.timezone) {
      return {
        ...task,
        localStartDay: task.start_day,
        localEndDay: task.end_day,
        localStartTime: task.start_time,
      };
    }

    let foreignStartDay: dayjs.Dayjs;
    let foreignEndDay: dayjs.Dayjs | undefined;

    if (task.timezone === "GMT" || task.timezone.startsWith("GMT+") || task.timezone.startsWith("GMT-")) {
      const gmtMatch = task.timezone.match(/GMT([+-]\d{2})(\d{2})?/);
      const offsetHours = gmtMatch?.[1] ? parseInt(gmtMatch[1]) : 0;
      const offsetMinutes = gmtMatch?.[2] ? parseInt(gmtMatch[2]) : 0;

      foreignStartDay = dayjs
        .utc(task.start_day)
        .add(task.start_time, "hour")
        .subtract(offsetHours, "hour")
        .subtract(offsetMinutes, "minute");

      foreignEndDay =
        task.end_day &&
        dayjs
          .utc(task.end_day)
          .add(task.start_time, "hour")
          .subtract(offsetHours, "hour")
          .subtract(offsetMinutes, "minute");
    } else if (task.timezone.includes("GMT")) {
      foreignStartDay = dayjs
        .utc(task.start_day)
        .add(task.start_time, "hour")
        .utcOffset(task.timezone);

      foreignEndDay =
        task.end_day &&
        dayjs
          .utc(task.end_day)
          .add(task.start_time, "hour")
          .utcOffset(task.timezone);
    } else {
      foreignStartDay = dayjs
        .tz(task.start_day, task.timezone)
        .startOf("day")
        .add(task.start_time, "hour");
      foreignEndDay =
        task.end_day &&
        dayjs
          .tz(task.end_day, task.timezone)
          .startOf("day")
          .add(task.start_time, "hour");
    }

    const localStartDay = foreignStartDay.tz(dayjs.tz.guess());
    const localEndDay = foreignEndDay?.tz(dayjs.tz.guess());

    return {
      ...task,
      localStartDay: localStartDay.format("YYYY-MM-DD"),
      localEndDay: localEndDay?.format("YYYY-MM-DD"),
      localStartTime: dayjs
        .duration({
          hours: localStartDay.hour(),
          minutes: localStartDay.minute(),
        })
        .asHours(),
    };
  } else {
    if (!task.timezone) {
      return {
        ...task,
        localDay: task.day,
        localStartTime: task.start_time,
      };
    }

    let foreignStartDay: dayjs.Dayjs;

    if (task.timezone === "GMT" || task.timezone.startsWith("GMT+") || task.timezone.startsWith("GMT-")) {
      const gmtMatch = task.timezone.match(/GMT([+-]\d{2})(\d{2})?/);
      const offsetHours = gmtMatch?.[1] ? parseInt(gmtMatch[1]) : 0;
      const offsetMinutes = gmtMatch?.[2] ? parseInt(gmtMatch[2]) : 0;

      foreignStartDay = dayjs
        .utc(task.day)
        .add(task.start_time, "hour")
        .subtract(offsetHours, "hour")
        .subtract(offsetMinutes, "minute");
    } else if (task.timezone.includes("GMT")) {
      const isTimeZonePresentInTimezones = timezones.find(
        (timezone) => timezone.identifier === task.timezone
      );

      if (!isTimeZonePresentInTimezones) {
        foreignStartDay = dayjs
          .utc(task.day)
          .add(task.start_time, "hour");
      } else {
        foreignStartDay = dayjs
          .utc(task.day)
          .add(task.start_time, "hour")
          .utcOffset(task.timezone);
      }
    } else {
      foreignStartDay = dayjs
        .tz(task.day, task.timezone)
        .startOf("day")
        .add(task.start_time, "hour");
    }

    const localStartDay = foreignStartDay.tz(dayjs.tz.guess());

    return {
      ...task,
      localDay: localStartDay.format("YYYY-MM-DD"),
      localStartTime: dayjs
        .duration({
          hours: localStartDay.hour(),
          minutes: localStartDay.minute(),
        })
        .asHours(),
    };
  }
};
