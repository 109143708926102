import { Box, Divider,Drawer } from "@mui/material";
import { useTasks,useUI } from "@structured/store";
import React from "react";

import { InboxHeader } from "../InboxTasks/InboxHeader";
import { InboxTasks } from "../InboxTasks/InboxTasks";

export const InboxDrawer: React.FC = () => {
  const { getInboxTasks } = useTasks();
  const {
    drawer: { isOpen, width },
  } = useUI();

  const getInboxTasksCount = () => {
    const tasks = getInboxTasks("all");
    let count = 0;
    count = tasks.filter(task => !task.completed_at).length;
    return count;
  };

  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width,
          boxSizing: "border-box",
          background: (theme) => theme.palette.background.default,
        },
      }}
      variant="persistent"
      anchor="left"
      open={isOpen}
    >
      <InboxHeader count={getInboxTasksCount()} />
      <Divider />
      <Box sx={{
        flexGrow: 1,
        overflowY: "auto",
      }}>
        <InboxTasks />
      </Box>
    </Drawer>
  );
};
