import webSymbols from "../web_symbols.json"

function getAllDeprecatedIcons(symbol: string): string[] {
    const deprecatedIcons: string[] = [];
    webSymbols.categories.forEach(category => {
        category.symbols.forEach(icon => {
            if (icon.id === symbol) {
                deprecatedIcons.push(...icon.deprecated);
            }
        });
    });
    return deprecatedIcons;
}

export default getAllDeprecatedIcons;